<template>
  <div class="sy">
    <!-- 页头 -->
    <div class="sy_top">数据库收益查询</div>
    <!-- 收益查询 -->
    <div class="sy_content">
      <div class="sy_content_top">（单位：知识币）</div>
      <div class="sy_content_table">
        <ul>
          <li class="table_top background">
            <span>合计总收益</span>
            <span>{{HJtotal}}</span>
          </li>
          <li>
            <span>已提现</span>
            <span>{{already}}</span>
          </li>
          <li class="background">
            <span>可提现</span>
            <span>{{can}}</span>
          </li>
        </ul>
        <p>
          <span class="WXTS">温馨提示：</span>
          <span class="color">此处收益可减收提成</span>
        </p>
      </div>
    </div>
    <!-- 统计图月收益 -->
    <div class="YSY">
      <div class="TJT">统计图月收益</div>
      <!-- 折线图 -->
      <div class="zxt">
        <V-Line style="width:98%;margin-top:20px"></V-Line>
      </div>
    </div>
    <div>
      <div>
        <span class="sy_right" style="margin-bottom:10px">（单位：知识币）</span>
      </div>
      <el-table
        :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
          background: '#F3F5F5',
          color: '#666',
          width: '100%',
        }"
        stripe
        border
        style="width: 100%;font-size:17px"
      >
        <el-table-column prop="name" label="数据库名称" width="180"></el-table-column>
        <el-table-column prop="pay" label="一次性付费"></el-table-column>
        <el-table-column prop="Monthlys" label="包月"></el-table-column>
        <el-table-column prop="revenue" label="总收益"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="tableData.length"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { DELETE, QUERY, INSERT, UPDATE, QUERYED } from "@/services/dao.js";
// import echarts from 'echarts'
import Line_chart from "@/views/Personal Center/Revenue_inquiry/Line_chart/index.vue";
export default {
  data() {
    return {
      HJtotal: "0", //合计总收益
      already: "0", //已提现
      can: "0", //可提现
      tableData: [
        {
          name: "数据库名称",
          pay: "一次性付费",
          Monthlys: "包月",
          revenue: "总收益"
        },
        {
          name: "数据库名称",
          pay: "一次性付费",
          Monthlys: "包月",
          revenue: "总收益"
        }
      ],
      currentPage: 1, //当前页
      pageSize: 10, //条数
      total: 500 ///总条数
    };
  },
  components: {
    "V-Line": Line_chart
  },
  created() {
    this.init(); //
    this.dataTame(); // 数据收益表
    //alert(this.$store.state.userinfo.id)
  },
  methods: {
    async init() {
      let totals = await QUERYED(
        "post",
        "",
        "  User(where: {id: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) { withdrawable_cash  cash_withdrawn  }"
      );
      if (totals.data.User[0].cash_withdrawn == undefined) {
        totals.data.User[0].cash_withdrawn = 0;
      } else if (totals.data.User[0].cash_withdrawn == undefined) {
        totals.data.User[0].withdrawable_cash = 0;
      }
      this.already = totals.data.User[0].cash_withdrawn; //已提现
      this.can = totals.data.User[0].withdrawable_cash; //已提现
      this.HJtotal = Number(this.already) + Number(this.can); //合计
    },
    async dataTame() {
      //alert(this.$store.state.userinfo.id)
      this.tableData = [];
      let name = await QUERYED(
        "post",
        "",
        "  dbInformation(where: {userid : {_eq: " +
          this.$store.state.userinfo.id +
          "},_not:{chargeType : {_eq:0}}}) {id dbName}"
      );
      for (let j = 0; j < name.data.dbInformation.length; j++) {
        //    包月
        let BY = await QUERYED(
          "post",
          "",
          "  dbEarnings_aggregate(where: {user_id: {_eq: " +
            this.$store.state.userinfo.id +
            "},dbName_id: {_eq:" +
            name.data.dbInformation[j].id +
            "},state : {_eq:2}}) {aggregate {   sum {   earnings    }  }}"
        );
        //一次性付费
        let YCX = await QUERYED(
          "post",
          "",
          "dbEarnings_aggregate(where: {user_id: {_eq: " +
            this.$store.state.userinfo.id +
            "},dbName_id: {_eq:" +
            name.data.dbInformation[j].id +
            "},state : {_eq:1}}) {aggregate {   sum {   earnings    }  }}"
        );
        this.tableData.push({
          name: name.data.dbInformation[j].dbName, //数据库名称
          pay: YCX.data.dbEarnings_aggregate.aggregate.sum.earnings, //一次性付费
          Monthlys: BY.data.dbEarnings_aggregate.aggregate.sum.earnings, //一次性付费
          revenue:
            Number(YCX.data.dbEarnings_aggregate.aggregate.sum.earnings) +
            Number(BY.data.dbEarnings_aggregate.aggregate.sum.earnings) //总收益
        });
      }
      this.total = this.tableData.length; //分页条数
    },
    //  斑马纹
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    // 每页多少条
    handleSizeChange(val) {
      this.pageSize = val;
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
    }
  },
  //调用
  mounted() {}
};
</script>
<style scoped>
.el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border-color: #000000;
  /* background: red; */
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #000000;
}
.el-table .warning-row {
  background: #f3f5f5;
}
.el-table .success-row {
  background: #f3f5f5;
}
.sy {
  width: 100%;
}
.sy_top {
  width: 100%;
  display: inline-block;
  height: 30px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  padding-bottom: 15px;
  border-bottom: 2px solid hsla(0, 0%, 90%, 1);
}
.sy_content {
  /* border: 1px solid red; */
  margin-top: 20px;
}
.sy_content_top {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: right;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.sy_right {
  float: right;
  text-align: right;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.sy_content_table {
  /* border: 1px solid forestgreen; */
  width: 100%;
}
.sy_content_table > ul {
  width: 98%;
  margin: auto;
  /* display: inline-block; */
  padding-left: 0%;
  /* border:1px solid hsla(0, 0%, 0%, 1) ; */
  height: 100%;
  margin-top: 5px;
}
.table_top {
  border-top: 1px solid hsla(0, 0%, 0%, 1);
  /* font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000; */
}
.sy_content_table > ul > li {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid hsla(0, 0%, 0%, 1);
  border-right: 1px solid hsla(0, 0%, 0%, 1);
  border-left: 1px solid hsla(0, 0%, 0%, 1);
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.sy_content_table > ul > li > span {
  width: 50%;
  display: inline-block;
  text-align: center;
}
.background {
  background: #f3f5f5;
}
.WXTS {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.color {
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: hsla(0, 0%, 49%, 1);
}
.TJT {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  width: 100%;
  /* border: 1px solid red; */
  margin-top: 60px;
  margin-bottom: 5px;
  /* height: 50px;
  vertical-align: bottom; */
}
.el-icon-caret-bottom {
  font-size: 20px;
  /* line-height: 5px; */
  position: relative;
  top: 2px;
}
.block {
  margin-top: 10px;
  text-align: right;
  /* border: 1px solid red; */
}
</style>