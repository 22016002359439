<template>
<div>
<span style="margin-right:30px" >
            <span class="color"> 所有数据库收益  </span>        
    <el-select v-model="Database_name" placeholder="请选择数据库" @change="onchange">
                <el-option  class="center_option" v-for="item in  options"  :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select></span>
    <span>            
         <el-select v-model="particular" placeholder="请选择年份" @change="onchange">
                <el-option  class="center_option" v-for="item in year" :key="item.value" :label="item.label" :value="item.label"></el-option>
            </el-select></span>
<span class="sy_right">（单位：知识币）</span> 
    <!--为echarts准备一个具备大小的容器dom-->
    <div class="zhext">
    <div id="main"  style="width: 100%;height: 400px;background-color: #FFFFFF;"></div>
    </div>
</div>
</template>
<script>
import { DELETE, QUERY, INSERT, UPDATE, QUERYED } from "@/services/dao.js";
    import echarts from 'echarts'
    export default {
        name: '',
        data() {
            return {
                charts: '',
            /*  opinion: ["1", "3", "3", "4", "5"],*/
                opinionData:[
                        ["3", "2", "4", "4", "5","3", "2", "4", "4", "5","4", "5"],
                        ["3", "50", "4", "4", "5","3", "2", "4", "4", "5","4", "5"],
                        ["3", "2", "4", "4", "100","3", "2", "4", "4", "5","4", "5"],
                        ["3", "2", "4", "4", "5","366", "2", "4", "4", "5","4", "5"],
                        ["3", "2", "4", "4", "5","3", "2", "4", "4", "5000","4", "5"],
                        ["3", "2", "4", "4222", "5","3", "2", "4", "4", "5","4", "5"],
                        ],////返回的数据（数据库状态数据）
                  options: [{
                        value: '0',
                        label: '数据库1'
                    },
                    {
                        value: '1',
                        label: '数据库2'
                    },
                    {
                        value: '2',
                        label: '数据库3'
                    },
                    {
                        value: '3',
                        label: '数据库4'
                    },
                    {
                        value: '4',
                        label: '数据库5'
                    },
                    {
                        value: '5',
                        label: '数据库6'
                    }
                ],
              year:[{value:"0",label:"2021"}],//年份数组
              particular :"",//年份(选中)
              Database_name:"",//数据库名（选中）

            }
        },
        created(){
            this.init();//年份
        },
        methods: {
            ///年份
          async   init(){
         let date = new Date();
        //  let y = date.getFullYear();
         for (let i = 0; i < this.year.length; i++) {
             if(this.year[i].label!=date.getFullYear())
             {///如果数组存在
             this.year.push(
                 {
                   value:i,
                   label:date.getFullYear()
                 }
             )
             }
         }
         console.log("年份：",this.year)
         // 数据库名
         this. options=[];//清空
     let name = await QUERYED(
        "post",
        "",
        "  dbInformation(where: {userid : {_eq: " +
          this.$store.state.userinfo.id +
          "},_not:{chargeType : {_eq:0}}}) {id dbName}");
       for (let j = 0; j < name.data.dbInformation.length; j++) {
           this. options.push(
               {
                   value:name.data.dbInformation[j].id,//数据库ID
                   label:name.data.dbInformation[j].dbName//数据库名
               }

           )
         }
         console.log("数据库名：",this. options)
            },
            ///数据库名查找
           async  onchange() {
               if(this.Database_name==""||this.Database_name==null)
               {
                  this.$message({
                   message: '请先选择数据库！',
                   type: 'warning'
                });
               }
               else{
        console.log("用户ID", this.$store.state.userinfo.id ,"数据库ID",this.Database_name,"年份：",this.particular)
        if(this.particular==""||this.particular==null){
             let date = new Date();
            this.particular=date.getFullYear();//当前年
        }
        let SY_profit=[];
                ///通过数据库ID和用户ID查找当前用户的当前年的余额
       for (let j = 1; j < 13; j++) {
        console.log("月份",j)
        let data=await QUERYED(
        "post",
        "",
        "  dbEarnings(where: {user_id  : {_eq: " +
          this.$store.state.userinfo.id +
          "},dbName_id: {_eq: " +
          this.Database_name +
          "},year: {_eq: " +
          this.particular +
          "},monthly : {_eq: " +
          j +
          "}}) { earnings    }");
        //alert(data.data.dbEarnings[j].earnings)
          console.log("收益",data)
          let money=0;
          for (let a = 0; a < data.data.dbEarnings.length; a++) {
                money+=Number(data.data.dbEarnings[a].earnings)
          }
          
          console.log(j,"月份:收入",money)
          SY_profit.push(money)//数据库ID
       }
       console.log("收益组装",SY_profit)
               ///通过ID查找名称
    let SYJ_name = await QUERYED(
        "post",
        "",
        "  dbInformation(where: {id : {_eq: " +
          this.Database_name +
          "}}) {dbName}");
       this.drawLine('main',SY_profit,SYJ_name.data.dbInformation[0].dbName);///渲染
        //   let SY_profit=[];
        // for (let j = 0; j < data.data.dbEarnings.length; j++) {
        //    SY_profit.push(data.data.dbEarnings[j].earnings)//数据库ID
        //  }
        //  console.log("收益组装",JSON.stringify(SY_profit))
        //  this.drawLine('main',JSON.stringify(SY_profit));
//                 console.log("value", value)
//                 this.opinionData.map((item, index) => {
//                     if (index == value) {
//                         console.log('item', item)
//                         console.log('index', index)
//                         //alert(this.opinionData[index])
//                         this.drawLine('main',this.opinionData[index]);
//                     }
//                 })
            /////
               }
            },
            drawLine(id,selectDate,dbName) {
                this.charts = echarts.init(document.getElementById(id))
                this.charts.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },
                    // legend: {
                    //     data: ['近七日收益']
                    // },
                    grid: {
                        left: '6%',
                        right: '7%',
                        bottom: '3%',
                        containLabel: true
                    },

                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        name:"月份",
                        type: 'category',
                        boundaryGap: false,
                    data: ["1","2","3","4","5","6","7","8","9","10","11","12"]
                    
                    },
                    yAxis: {
                        name:"总收益（知识币）",
                        type: 'value'
                    },

                    series: [{
                        name: dbName,///悬浮显示的名称
                        type: 'line',
                        stack: '总量',
                        data: selectDate
                    }]
                })
            },
        },
        //调用
        mounted() {
            this.$nextTick(function() {
                this.drawLine('main',[],"")
            })
        }
    }
</script>
<style scoped>
    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .zhext{
        width: 100%;
        /* margin:auto; */
        margin-top: 20px;
        background-color: #E5E5E5;
        border: 1px solid #000;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
        margin-bottom: 60px;
    }
      .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .center_option{
      /* background-color: fuchsia; */
      text-align: center;
  }
  .sy_right{
  float: right;
  /* text-align: right; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.color{
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: hsla(0, 0%, 49%, 1);
}
</style>